import Donation from '@/services/Donation';
import Candles from '@/services/Candles';
import Stones from '@/services/Stones';
import Pipes from '@/services/Pipes';

const initialState = () => ({
  paymentMethod: 'paypal',
  postType: null,
  postId: null,
  donationPostId: null,
  amount: null,
  formData: {},
  loaded: true,
  stripeCardData: {
    cvv: '',
    number: '',
    expiry: '',
  },
  creditCardData: {
    bankAccountOwner: '',
    bankIban: '',
    // bankBic: '',
  },
});

const state = initialState();

// getters
const getters = {
  isStripe(state) {
    return state.paymentMethod === 'stripe_credit_card';
  },
  isSepa(state) {
    return state.paymentMethod === 'wikando_direct_debit';
  },
  sepaFormIsEmpty(state, getters) {
    return (
      getters.isSepa &&
      (!state.creditCardData.bankAccountOwner.trim() ||
        !state.creditCardData.bankIban.trim())
      // || !state.creditCardData.bankBic.trim()
    );
  },
  stripeFormIsEmpty(state, getters) {
    return (
      getters.isStripe &&
      (!state.creditCardData.bankAccountOwner.trim() ||
        !state.stripeCardData.cvv.trim() ||
        !state.stripeCardData.number.trim() ||
        !state.stripeCardData.expiry.trim() ||
        state.stripeCardData.expiry.length < 5)
    );
  },
};

// actions
const actions = {
  pay({ rootState, state, commit, dispatch, getters }, payload) {
    if (getters.sepaFormIsEmpty || getters.stripeFormIsEmpty) return;

    if (!state.loaded) return;
    commit('setLoaded', false);

    const { postType } = payload;

    let expiry = '';
    if (state.stripeCardData.expiry) {
      expiry = state.stripeCardData.expiry.replace('/', '/20');
    }

    const params = {
      donator: {
        salutation: state.formData.anrede.value,
        first_name: state.formData.vorname.value,
        last_name: state.formData.nachname.value,
        email: state.formData.email.value,
        ip: rootState.global.settings.user_ip,

        ...(state.formData.telefonnummer && {
          phone: state.formData.telefonnummer.value,
        }),

        ...(localStorage.getItem('donationReceipt') === 'yes' && {
          donation_receipt: 'yes',
          street: state.formData.strabe.value,
          number: state.formData.nr.value,
          zip_code: state.formData.plz.value,
          // country: ....
        }),

        ...(localStorage.getItem('donationCompanyName') === 'yes' && {
          donate_company: 'yes',
          company_name: state.formData.firmenname.value,
        }),

        // TODO : NEW FIELDS
        country: state.formData.land.value, // country: state.formData.land.value,
        city: state.formData.city.value,

        amount: ['candles', 'stones', 'organ'].includes(postType)
          ? payload.candle.amount
          : payload.donation.donation_amount,
        recurring_payments: ['candles', 'stones', 'organ'].includes(postType)
          ? 'no'
          : payload.donation.recurring_payments,
        payment_method: state.paymentMethod, // "direct_debit" or "paypal",
        ...(getters.isSepa && {
          bank_account_owner: state.creditCardData.bankAccountOwner,
          bank_iban: state.creditCardData.bankIban,
          // bank_bic: state.creditCardData.bankBic,
        }),

        ...(getters.isStripe && {
          credit_card_owner: state.creditCardData.bankAccountOwner,
          credit_card_number: state.stripeCardData.number,
          credit_card_secure_id: state.stripeCardData.cvv,
          credit_card_expiry: expiry,
        }),
      },
    };

    commit('setPostType', postType);

    if (postType === 'donations') {
      params.donation = payload.donation;
      dispatch('donationProductPay', params);
    } else if (postType === 'candles') {
      params.candle = payload.candle;
      dispatch('candlePay', params);
    } else if (postType === 'stones') {
      params.stone_id = payload.stone_id;
      params.paid_price = payload.paid_price;

      params.stone = {
        // stone_from: rootState.stones.previewData.candle_from,
        // stone_for: rootState.stones.previewData.candle_for,
        message: rootState.stones.previewData.message,
      };

      dispatch('stonePay', params);
    } else if (postType === 'organ') {
      params.pipe_id = payload.pipe_id;
      params.paid_price = payload.paid_price;
      params.pipe = {
        message: rootState.organ.previewData.message,
      };
      dispatch('pipePay', params);
    }
  },
  donationProductPay({ commit }, payload) {
    // let isSuccess = false;
    commit('preloader/show', null, { root: true });
    Donation.create(payload)
      .then(({ data }) => {
        commit('setPostId', data.post_id);
        commit('setDonationPostId', data.post_id);
        window.location.href = data.redirect_url;
        // isSuccess = true;
      })
      .catch((e) => {
        // console.log('Error', e);
        let errors = {};

        for (const errorsKey in e.response.data.errors) {
          errors = { ...errors, ...e.response.data.errors[errorsKey] };
        }

        commit('errors/setErrors', errors, { root: true });
      })
      .finally(() => {
        commit('setLoaded', true);
        commit('preloader/hide', null, { root: true });
      });
  },
  candlePay({ commit, rootState }, payload) {
    // let isSuccess = false;
    commit('preloader/show', null, { root: true });
    Candles.create(payload)
      .then(({ data }) => {
        commit('setPostId', data.post_id);
        commit('setDonationPostId', data.post_id);
        window.location.href = data.redirect_url;
        // isSuccess = true;
        localStorage.setItem(
          'candlePreviewData',
          JSON.stringify(rootState.donationCandles.previewData)
        );
      })
      .catch((e) => {
        // console.log('Error', e);
        let errors = {};

        for (const errorsKey in e.response.data.errors) {
          errors = { ...errors, ...e.response.data.errors[errorsKey] };
        }

        commit('errors/setErrors', errors, { root: true });
      })
      .finally(() => {
        commit('setLoaded', true);
        commit('preloader/hide', null, { root: true });
      });
  },
  stonePay({ commit }, payload) {
    // let isSuccess = false;
    commit('preloader/show', null, { root: true });
    Stones.create(payload)
      .then(({ data }) => {
        commit('setPostId', data.post_id);
        commit('setDonationPostId', data.post_id);
        console.log(data);
        window.location.href = data.redirect_url;
        // isSuccess = true;
        commit('stones/setFormCurrentStep', 3);
      })
      .catch((e) => {
        // console.log('Error', e);
        let errors = {};

        for (const errorsKey in e.response.data.errors) {
          errors = { ...errors, ...e.response.data.errors[errorsKey] };
        }

        commit('errors/setErrors', errors, { root: true });
      })
      .finally(() => {
        commit('setLoaded', true);
        commit('preloader/hide', null, { root: true });
      });
  },
  pipePay({ commit }, payload) {
    // let isSuccess = false;
    commit('preloader/show', null, { root: true });
    Pipes.create(payload)
      .then(({ data }) => {
        commit('setPostId', data.post_id);
        commit('setDonationPostId', data.post_id);
        window.location.href = data.redirect_url;
        // isSuccess = true;
      })
      .catch((e) => {
        // console.log('Error', e);
        let errors = {};

        for (const errorsKey in e.response.data.errors) {
          errors = { ...errors, ...e.response.data.errors[errorsKey] };
        }

        commit('errors/setErrors', errors, { root: true });
      })
      .finally(() => {
        commit('setLoaded', true);
        commit('preloader/hide', null, { root: true });
      });
  },
};

// mutations
const mutations = {
  setPaymentMethod(state, payload) {
    state.paymentMethod = payload;
  },
  setPostId(state, payload) {
    state.postId = payload;
  },
  setDonationPostId(state, payload) {
    state.donationPostId = payload;
  },
  setAmount(state, payload) {
    state.amount = payload;
  },
  setFormData(state, payload) {
    state.formData = payload;
  },
  setBankAccountOwner(state, payload) {
    state.creditCardData.bankAccountOwner = payload;
  },
  setBankIban(state, payload) {
    state.creditCardData.bankIban = payload;
  },
  // setBankBic(state, payload) {
  //   state.creditCardData.bankBic = payload;
  // },
  setPostType(state, payload) {
    state.postType = payload;
  },
  setLoaded(state, payload) {
    state.loaded = payload;
  },
  reset(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setStripeCardCvv(state, payload) {
    state.stripeCardData.cvv = payload;
  },
  setStripeCardNumber(state, payload) {
    state.stripeCardData.number = payload;
  },
  setStripeCardExpiry(state, payload) {
    state.stripeCardData.expiry = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
